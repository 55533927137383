<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <ConfigurationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <h4>Create a view</h4>
            <p class="text-muted">
              Create a new order view with a selection of custom fields.
            </p>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div
                class="form-group col-12 required"
                :class="{ 'is-invalid': errors.has('name') }"
              >
                <label for="name" class="control-label text-capitalize"
                  >View name</label
                >
                <input
                  id="name"
                  v-model="form.data.name"
                  v-validate="{ required: true }"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="VIP customers"
                  autocomplete="off"
                />
                <span v-show="errors.has('name')" class="invalid-feedback">{{
                  errors.first("name")
                }}</span>
              </div>
              <div
                class="form-group col-md-6 required"
                :class="{ 'is-invalid': errors.has('access') }"
              >
                <label for="access" class="control-label text-capitalize"
                  >Access level:</label
                >
                <select
                  id="access"
                  v-model="access"
                  v-validate="{ required: true }"
                  name="role"
                  class="form-control"
                >
                  <option
                    v-for="opt in accessOptions()"
                    :value="opt.val"
                    :key="opt.val"
                  >
                    {{ opt.name }}
                  </option>
                </select>
                <span v-show="errors.has('access')" class="invalid-feedback">{{
                  errors.first("access")
                }}</span>
              </div>
              <div
                v-if="access === 'role'"
                class="form-group col-md-6 required"
                :class="{ 'is-invalid': errors.has('role') }"
              >
                <label for="role" class="control-label text-capitalize"
                  >Role:</label
                >
                <select
                  id="role"
                  v-model="role"
                  v-validate="{ required: true }"
                  name="role"
                  class="form-control"
                >
                  <option
                    v-for="avlRole in roles"
                    :value="avlRole.value"
                    :key="avlRole.value"
                  >
                    {{ avlRole.text }}
                  </option>
                </select>
                <span v-show="errors.has('role')" class="invalid-feedback">{{
                  errors.first("role")
                }}</span>
              </div>
            </div>
          </div>
          <div class="card-footer bg-white">
            <ButtonLoading
              :loading="loading"
              :title="'Save view'"
              :class="'btn btn-secondary float-right'"
              v-on:click.native="submitForm"
            />
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import Permission from "@/utils/permission";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import { postToApi } from "@/helpers/apiConnection";
import utilities from "@/helpers/utilities";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "CreateSegment",
  components: {
    Default,
    ConfigurationNav,
    ButtonLoading,
  },
  extends: Permission,
  data: function() {
    return {
      layout: "Default",
      loading: false,
      access: "organization",
      role: "contributor",
      form: {
        data: { name: "" },
        group: "view",
      },
      roles: [
        { text: "Contributor", value: "contributor" },
        { text: "Admin", value: "admin" },
      ],
      step_status: {
        key: "create_view",
        status: true,
      },
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    accessOptions() {
      const opts = [{ val: "personal", name: "Personal" }];
      const canCreateRoleView = this.hasPermission('create-role-view');
      if (canCreateRoleView) {
        opts.push({ val: "role", name: "Role" });
      }
      const canCreateOrgView = this.hasPermission('create-organization-view');
      if (canCreateOrgView) {
        opts.push({ val: "organization", name: "Organization" });
      }
      return opts;
    },
    submitForm: function() {
      this.loading = true;
      // mark process complete
      this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);

      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.form = utilities.cleanData(this.form);
          switch (this.access) {
            case "personal":
              this.form.owner_id = this.getUser.id;
              break;
            case "role":
              this.form.role = this.role;
              break;
          }
          const result = await postToApi(
            "/configurationData",
            this.form,
            "View saved"
          ).catch((error) => {
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$validator.errors.add({
                field: key,
                msg: error.response.data.errors[key][0],
              });
            });
          });
          if (result) {
            this.$router.push({
              name: "configuration.view.edit",
              params: { id: result.id },
            });
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    deleteCondition(index) {
      this.$delete(this.form.conditions, index);
    },
    addCondition() {
      this.form.conditions.push({
        field: "total",
        type: "order",
        operator: "==",
        value: "",
      });
    },
  },
};
</script>

<style scoped></style>
